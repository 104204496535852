import {
    VuexModule,
    Module,
    Mutation,
    Action,
    MutationAction,
} from "vuex-module-decorators";
import { Client, TrancheTarif } from "./types";
import * as api from "./api";
import axios from "axios";

@Module({ name: "user" })
export default class UserStoreModule extends VuexModule {
    public clients: Array<Client> = [];
    public currentClient: Client | null = null;
    userId: number | null = null;
    grilleTarif: Array<TrancheTarif> = [];

    get isMultiClient(): boolean {
        return false;
        // return this.clients.length > 1;
    }

    get getClient() {
        return this.currentClient;
        // return (idClient: number): Client | null => {
        //     const cli = this.clients.find(c => c.id === idClient);

        //     if (cli != undefined) {
        //         return cli;
        //     } else {
        //         return null;
        //     }
        // };
    }
    get getMontantTransport() {
        return (poidsTotal: number): number => {
            const tranche = this.grilleTarif.find(
                (t) => poidsTotal <= t.poidsMax
            );

            if (tranche) {
                return tranche.prix;
            } else {
                return 0;
            }
        };
    }

    @Mutation
    setClients(clients: Array<Client>) {
        this.clients = clients;
    }

    @Mutation
    setUserId(id: number) {
        this.userId = id;
    }
    @Mutation
    setCurrentClient(client: Client) {
        this.currentClient = client;
    }

    @Action
    async fetchUser(): Promise<void> {
        const response = await axios.get("/api/user");

        this.context.commit("setUserId", response.data.data.userId);
        this.context.commit("setClients", response.data.data.clients);
        this.context.commit("setCurrentClient", response.data.data.client);
    }

    @MutationAction
    async fetchGrilleTarif() {
        const grille = await api.getTrancheTarif();
        return {
            grilleTarif: grille,
        };
    }

    @Action
    async fetchIfNeededUser(): Promise<void> {
        if (this.userId == null) {
            return this.fetchUser();
        }
    }
}
