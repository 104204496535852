<template>
    <li class="nav-item dropdown ">
        <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="fas fa-shopping-cart" aria-hidden="true"></i>
            Panier
            <span class="badge badge-warning ">{{ nbArticles }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span class="dropdown-item dropdown-header">
                {{ nbArticles }} articles
            </span>
            <div class="dropdown-divider"></div>

            <div
                v-for="(produit, index) in produits"
                :key="index"
                href="#"
                class="dropdown-item"
            >
                {{ produit.quantiteTotale.toLocaleString(undefined, {maximumFractionDigits: 0}) }} {{ produit.nom }}
            </div>

            <div class="dropdown-divider"></div>
            <div class="dropdown-item dropdown-footer">
                <a class="btn btn-sm btn-primary" role="button" href="/cart" title="Commander">
                    <i class="fas fa-shopping-cart" aria-hidden="true"></i>&nbsp;Commander
                </a>
                <a class="btn btn-sm btn-danger" role="button" href="#" title="Vider" @click="emptyCart">
                    <i class="fas fa-trash-alt" aria-hidden="true"></i>&nbsp;Vider
                </a>
            </div>
        </div>
    </li>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from 'vuex-module-decorators'
import { store } from '../store'
import CartStoreModule from '../store/CartStore'

import { Produit } from "../store/types";

const cartStore = getModule(CartStoreModule, store)

@Component
export default class CartButton extends Vue {

    get nbArticles(): number{
        return cartStore.nbArticles
    }

    get produits(){
        return cartStore.cartProduits
    }

    public emptyCart(){
        cartStore.emptyCart()
    }
}
</script>

<style></style>
