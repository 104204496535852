import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import CartStoreModule from "./CartStore";
import Catalogue from "./ProductStore";
import UserStoreModule from "./UserStore";

import VuexPersistence from "vuex-persist";
const vuexLocal = new VuexPersistence({
    key: "PetCart",
    storage: window.localStorage,
    modules: ["cart"]
});

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        catalogue: Catalogue,
        cart: CartStoreModule,
        user: UserStoreModule
    },
    plugins: [vuexLocal.plugin]
});
