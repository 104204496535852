export class Produit {
           id: number;
           nom: string;
           refClient: string;
           imageUrl: string;
           description: string;
           conditionnement: number;
           prixUnitaire: number;
           poidsUnitaire: number;
           quantiteMaxAnnuelle: number;
           quantiteMaxCommandable: number;
           quantiteConsommee: number;

           constructor(
               id: number,
               nom: string,
               refClient: string,
               imageUrl: string,
               description: string,
               conditionnement: number,
               prixUnitaire: number,
               poidsUnitaire: number,
               quantiteMaxAnnuelle: number,
               quantiteMaxCommandable: number,
               quantiteConsommee: number
           ) {
               this.id = id;
               this.nom = nom;
               this.refClient = refClient;
               this.imageUrl = imageUrl;
               this.description = description;
               this.conditionnement = conditionnement;
               this.prixUnitaire = prixUnitaire;
               this.poidsUnitaire = poidsUnitaire;
               this.quantiteMaxAnnuelle = quantiteMaxAnnuelle;
               this.quantiteMaxCommandable = quantiteMaxCommandable;
               this.quantiteConsommee = quantiteConsommee;
           }
       }

export class CartProduit extends Produit {
    quantite: number;
    quantiteTotale: number;

    constructor(produit: Produit, quantite: number) {
        super(
            produit.id,
            produit.nom,
            produit.refClient,
            produit.imageUrl,
            produit.description,
            produit.conditionnement,
            produit.prixUnitaire,
            produit.poidsUnitaire,
            produit.quantiteMaxAnnuelle,
            produit.quantiteMaxCommandable,
            produit.quantiteConsommee
        );
        this.quantite = quantite;
        this.quantiteTotale = quantite * produit.conditionnement;
    }

    public setQuantite(quantite: number) {
        console.log('set quantite ' + quantite + ' to '+ this.nom)
        this.quantite = quantite;
        this.quantiteTotale = quantite * this.conditionnement;
    }
}

export class Client {
    id: number;
    nom: string = "";
    adresseLivraison: Array<string>;

    constructor(id: number, nom: string, adresseLivraison: Array<string>) {
        this.id = id;
        this.nom = nom;
        this.adresseLivraison = adresseLivraison;
    }
}

export class TrancheTarif {
    poidsMax: number = 0;
    nomTransporteur: string = "";
    prix: number = 0;
}
