import jQuery from "jquery";
import * as moment from "moment";
import "moment/locale/fr";

moment.locale("fr");

require("tempusdominus-bootstrap-4");

require("admin-lte/plugins/select2/js/select2.full");
require("admin-lte/plugins/daterangepicker/daterangepicker.js");

declare var window: any;
window.$ = window.jQuery = jQuery;
window.moment = moment;

jQuery(document).ready(function() {
    jQuery(".select2").select2();

    jQuery(".datetimepicker").each(function() {
        var $this = jQuery(this);
        console.log(moment($this.data().date));
        $this.datetimepicker({
            locale: "fr",
            defaultDate: moment($this.data().date),
            icons: {
                time: "fa fa-clock",
                date: "fa fa-calendar-alt"
            }
        });
    });

    jQuery("#auto-download").each(function() {
        var $this = jQuery(this);
        setTimeout(function() {
            window.location = $this.attr("href");
        }, 500);
    });
});
