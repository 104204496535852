import axios from "axios";
import { Client, TrancheTarif } from "./types";

export interface tarifTransportResponse {
    data: Array<TrancheTarif>;
}

export async function getTrancheTarif() {
    const response = await axios.get("/api/transportTarifs");
    return (response.data as tarifTransportResponse).data;
}
