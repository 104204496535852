import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Produit } from "./types";
import axios from "axios";

@Module({ name: "catalogue" })
export default class Catalogue extends VuexModule {
    produits: Array<Produit> = []; // initialise empty for now

    get nbProduits(): number {
        return this.produits.length;
    }

    @Mutation
    setProduits(produits: Array<Produit>) {
        // console.log("mutation : " + produits);
        this.produits = produits;
    }

    @Action
    async fetchProduits(): Promise<void> {
        const response = await axios.get("/api/produits");
        // console.log(response.data.data);
        const prods = response.data.data;

        this.context.commit("setProduits", prods);
    }
}
