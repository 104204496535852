<template>
    <form action="" class="h-100 w-100 row">
        <div class="col-6">
            <vue-ellipse-progress
                v-if="produit.quantiteMaxAnnuelle >= 0"
                :progress="Math.min(getConsoPourcent(), 100)"
                :angle="90"
                :color="getConsoColor()"
                emptyColor="#8ec5fc"
                :size="70"
                :thickness="6"
                emptyThickness="2%"
                lineMode="out 1"
                :legend-value="getConsoPourcent()"
            >
                <span slot="legend-value"> %</span>
            </vue-ellipse-progress>
            <div v-if="produit.quantiteMaxAnnuelle >= 0">
                {{
                    getConso().toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                    })
                }}
                /
                {{
                    produit.quantiteMaxAnnuelle.toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                    })
                }}
            </div>
        </div>
        <div class="col-6">
            <div class="d-flex w-100 flex-column justify-content-center">
                <vue-numeric-input
                    class="mb-2 mx-auto"
                    style="width: 6em"
                    v-model="Qte"
                    :min="getIncrementMin()"
                    :max="getIncrementMax()"
                    align="center"
                >
                </vue-numeric-input>
                <button
                    class="btn btn-sm btn-primary mx-auto"
                    @click.prevent="commander"
                    title="Ajouter au panier"
                    :disabled="Qte == 0"
                >
                    <i class="fas fa-cart-arrow-down" aria-hidden="true"></i>
                    &nbsp;Ajouter au panier
                </button>
                <transition name="fade">
                    <p v-if="addedMessage" class="text-success mx-auto">
                        Ajouté au panier
                    </p>
                </transition>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { store } from "../store";
import CartStoreModule from "../store/CartStore";

import { CartProduit, Produit } from "../store/types";
import VueNumericInput from "vue-numeric-input";

const cartStore = getModule(CartStoreModule, store);

@Component({
    components: {
        VueNumericInput,
    },
})
export default class BoutonCommande extends Vue {
    // @Prop({type: Produit}) produit!: Produit
    @Prop() produit!: Produit;

    public getIncrementMax() {
        if (this.produit.quantiteMaxCommandable < 0) {
            return Infinity;
        } else {
            return Math.ceil(
                (this.produit.quantiteMaxCommandable -
                    this.produit.quantiteConsommee) /
                    this.produit.conditionnement
            );
        }
    }
    public getIncrementMin() {
        // if (this.produit.quantiteMaxAnnuelle == 0) {
        //     return 0;
        // } else {
        //     return 1;
        // }
        return 0;
    }

    public getConso(): number {
        return (
            this.produit.quantiteConsommee +
                (this.Qte ?? 0) * this.produit.conditionnement ?? 0
        );
    }

    public getConsoPourcent(): number {
        return this.produit.quantiteMaxAnnuelle == 0
            ? 100
            : Math.round(
                  (this.getConso() * 100) / this.produit.quantiteMaxAnnuelle
              );
    }

    public getConsoColor(): string {
        if (this.getConsoPourcent() <= 80) {
            return "green";
        }
        if (this.getConsoPourcent() <= 100) {
            return "orange";
        }
        return "red";
    }

    Qte: number = this.getIncrementMin();
    addedMessage: boolean = false;
    percent: number = this.getConsoPourcent();

    public commander() {
        cartStore.addToCart({ produit: this.produit, qte: this.Qte });
        this.addedMessage = true;
        // this.Qte=0;
        setTimeout(() => {
            this.addedMessage = false;
        }, 1000);
    }
}
</script>

<style>
.fade-enter-active {
    transition: opacity 0.5s;
}
.fade-leave-active {
    transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
