/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import "./bootstrap";
import "./forms";

import Alpine from "alpinejs";

window.Alpine = Alpine;

import "../../vendor/proeasyprint/pep-ui/resources/js/app.js";

import Vue from "vue";
import "livewire-vue";
import { store } from "./store";

import BoutonCommande from "./components/BoutonCommande.vue";
Vue.component("bouton-commande", BoutonCommande);

import CartButton from "./components/CartButton.vue";
Vue.component("cart-button", CartButton);

import Cart from "./components/Cart.vue";
Vue.component("cart", Cart);

import Catalogue from "./components/Catalogue.vue";
Vue.component("catalogue", Catalogue);

import EditQuantite from "./components/commandes/EditQuantite.vue";
Vue.component("editCommandeQuantite", EditQuantite);

import VueEllipseProgress from "vue-ellipse-progress";
Vue.use(VueEllipseProgress);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app",
    store,
});

Alpine.start();
