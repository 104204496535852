<template>
<div class="card">
    <div class="card-body">
        <table class="table">
            <tbody>
                <tr class="d-flex"  v-for="(produit, index) in produits" :key="index">
                    <td class="col-2"><img :src="produit.imageUrl" alt="Image produit"></td>
                    <td class="col-5">
                        <div class='row'>
                            <div class="col"><strong>{{ produit.nom }}</strong></div>
                            <div class="col col-auto"><strong>Ref : </strong>{{ produit.refClient }}</div>
                        </div>
                        <div class="text-sm mt-1 text-muted" v-html="nl2br(produit.description)"></div>
                    <td class="col-2">
                        <div class='row'>
                            <strong>Prix&nbsp;HT&nbsp;:</strong>&nbsp;{{ produit.prixUnitaire.toFixed(2) }}&nbsp;€
                        </div>
                        <div v-if="produit.conditionnement>1" class='row'>
                            <strong>Par&nbsp;</strong>{{ produit.conditionnement }}
                        </div>
                    </td>
                    <td class="col-3">
                        <bouton-commande :produit="produit"> </bouton-commande>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from 'vuex-module-decorators'
import { store } from '../store'
import CatalogueStoreModule from '../store/ProductStore'
import UserStoreModule from '../store/UserStore'

import BoutonCommande from './BoutonCommande.vue'

import { Produit } from "../store/types";

const catalogueStore = getModule(CatalogueStoreModule, store)
const userStore = getModule(UserStoreModule, store)

@Component({
    components: {
        BoutonCommande
    }
})
export default class Catalogue extends Vue {

    get produits(){
        return catalogueStore.produits
    }

    public mounted() {
        userStore.fetchIfNeededUser()
        catalogueStore.fetchProduits()
    }

    public nl2br(txt: string):string
    {
        return txt.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }

}

</script>
