<template>
    <div>
        <form v-if="editMode" :action="editRoute" method="post">
            <input :value="csrfToken" name="_token" type="hidden" />
            <input type="hidden" name="_method" value="PATCH" />
            <input id="quantite" name="quantite" type="hidden" :value="Qte" />
            <vue-numeric-input
                style="width: 7em"
                v-model="Qte"
                :min="1"
                align="center"
            >
            </vue-numeric-input>

            <button class="btn btn-sm badge-btn badge-success" type="submit">
                <i class="fas fa-check"></i>
            </button>
            <button
                class="btn btn-sm badge-btn badge-danger"
                @click.prevent="cancel"
            >
                <i class="fas fa-times"></i>
            </button>
        </form>
        <div v-else>
            <span class="mr-2">{{ Qte }}</span>
            <button
                class="btn btn-sm badge-btn badge-primary"
                @click.prevent="setEditMode"
            >
                <i class="fas fa-pencil-alt"></i>
            </button>
            <form
                v-if="deleteRoute"
                :action="deleteRoute"
                method="post"
                class="d-inline-block"
            >
                <input :value="csrfToken" name="_token" type="hidden" />
                <input type="hidden" name="_method" value="DELETE" />
                <button
                    class="btn btn-sm badge-btn badge-danger"
                    type="submit"
                    title="Supprimer"
                >
                    <i class="fas fa-trash"></i>
                </button>
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
// import { store } from '../../store'

import VueNumericInput from "vue-numeric-input";

@Component({
    components: {
        VueNumericInput
    }
})
export default class BoutonCommande extends Vue {
    // @Prop({type: Produit}) produit!: Produit
    @Prop() quantite!: number;
    @Prop() editRoute!: string;
    @Prop() deleteRoute!: string;

    editMode: boolean = false;
    Qte: number = this.quantite;
    csrfToken: string = "";

    public setEditMode() {
        this.editMode = true;
    }

    public deleteProduit() {
        this.editMode = false;
    }
    public cancel() {
        this.Qte = this.quantite;
        this.editMode = false;
    }
    public mounted() {
        this.csrfToken = document.querySelector<HTMLMetaElement>(
            'meta[name="csrf-token"]'
        )!.content;
    }
}
</script>

<style></style>
