<template>
    <div class="card">
        <!-- <div class="card-header">
            <h3 class="card-title">
                    panier header
            </h3>
        </div> -->

        <div class="card-body">
            <div v-if="messageSuccessCommande" class="col-12">
                Votre commande à été enregistrée sous le No
                {{ messageSuccessCommande }}
            </div>
            <div v-if="messageSuccessCommande" class="col-12 mt-3">
                <a href="/">Retour au tableau de bord</a>
            </div>
            <div v-if="isCartEmpty && !messageSuccessCommande" class="col-12">
                Votre panier est vide
            </div>
            <div v-if="!isCartEmpty" class="col-12">
                <table class="table">
                    <thead>
                        <th>Ref</th>
                        <th>Article</th>
                        <th>Conditionnement</th>
                        <th colspan="2">Quantite</th>
                        <th>Quota annuel</th>

                        <th class="text-right">Prix</th>
                        <th class="text-right">Total</th>
                    </thead>
                    <tbody>
                        <tr v-for="(produit, index) in produits" :key="index">
                            <td>{{ produit.refClient }}</td>
                            <td>{{ produit.nom }}</td>
                            <td>{{ produit.conditionnement }}</td>
                            <td>
                                {{
                                    produit.quantiteTotale.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )
                                }}
                            </td>
                            <td>
                                <div class="row">
                                    <!-- <div class="col-auto">
                                        {{ produit.quantiteTotale.toLocaleString(undefined, {maximumFractionDigits: 0}) }}
                                    </div> -->
                                    <div class="col-auto mr-0">
                                        <vue-numeric-input
                                            style="width: 6em"
                                            v-model="produit.quantite"
                                            @input="setQuantite(produit)"
                                            :min="getIncrementMin(produit)"
                                            :max="getIncrementMax(produit)"
                                            align="center"
                                        />
                                    </div>
                                    <div class="col-automl-1">
                                        <a
                                            class="btn btn-xs btn-danger"
                                            role="button"
                                            href="#"
                                            title="Supprimer le produit du panier"
                                            @click.prevent="remove(produit)"
                                        >
                                            <i
                                                class="fas fa-trash-alt"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div
                                    v-if="produit.quantiteMaxAnnuelle >= 0"
                                    class="d-flex flex-row"
                                >
                                    <vue-ellipse-progress
                                        :progress="
                                            Math.min(
                                                getConsoPourcent(produit),
                                                100
                                            )
                                        "
                                        :angle="90"
                                        :color="getConsoColor(produit)"
                                        emptyColor="#8ec5fc"
                                        :size="30"
                                        :thickness="5"
                                        emptyThickness="2%"
                                        lineMode="out 1"
                                        :legend="false"
                                    >
                                    </vue-ellipse-progress>
                                    <div class="ml-2 my-auto">
                                        {{ getConsoPourcent(produit) }} % ({{
                                            getConso(produit).toLocaleString(
                                                undefined,
                                                { maximumFractionDigits: 0 }
                                            )
                                        }}
                                        /
                                        {{
                                            produit.quantiteMaxAnnuelle.toLocaleString(
                                                undefined,
                                                { maximumFractionDigits: 0 }
                                            )
                                        }}
                                        )
                                    </div>
                                </div>
                            </td>
                            <td class="text-right">
                                {{ produit.prixUnitaire.toFixed(2) }}&nbsp;€
                            </td>
                            <td class="text-right">
                                {{
                                    (
                                        produit.prixUnitaire * produit.quantite
                                    ).toFixed(2)
                                }}&nbsp;€
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="!isCartEmpty" class="row mt-4">
                <div class="col">
                    <div class="row" style="max-width: 500px" v-if="client">
                        <div v-if="!isMultiClient" class="col-12">
                            Livraison : {{ client.nom }}
                        </div>
                        <div v-else class="col-12">
                            <strong> Livraison : </strong>
                            <select v-model="currentClient">
                                <option
                                    v-for="c in clients"
                                    :value="c"
                                    :key="c.id"
                                >
                                    {{ c.nom }}
                                </option>
                            </select>
                        </div>
                        <div class="col-12">
                            <strong> Adresse : </strong> <br />
                            <span
                                v-for="(ligne, index) in client.adresseLivraison
                                    .slice(0, 2)
                                    .filter(
                                        (addr) =>
                                            addr != null && addr.length > 0
                                    )"
                                :key="index"
                            >
                                {{ ligne }} <br />
                            </span>

                            <input
                                type="text"
                                v-model="ligneAdresse"
                                placeholder="ligne 3"
                                maxlength="45"
                                class="w-100"
                            /><br />

                            <span
                                v-for="(ligne, index) in client.adresseLivraison
                                    .slice(3)
                                    .filter(
                                        (addr) =>
                                            addr != null && addr.length > 0
                                    )"
                                :key="index + 4"
                            >
                                {{ ligne }} <br />
                            </span>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <em class="text-danger text-bold">A partir du 7 octobre 2024 le numéro de commande SAP Ariba est obligatoire : 10 chiffres commencant par 45000xxxxx + Code livraison Bxxx</em>
                        </br>
                        <strong> Votre numéro de commande : </strong>
                        <input type="text" style="width: 6em;" v-model="commandeRefClient" />
                        <strong class="ml-2"> Code livraison : </strong>
                        <input type="text" style="width: 4em;" v-model="commandeCodeLivraison" placeholder="Bxxx"/>
                        

                    </div>
                    <div class="form-group mt-3">
                        <textarea
                            v-model="commentCommande"
                            class="form-control"
                            rows="3"
                            placeholder="message ..."
                        ></textarea>
                    </div>
                </div>

                <div v-if="!isCartEmpty" class="col-4 ml-auto">
                    <div class="row h-100">
                        <table class="col-12 table mb-0">
                            <tbody>
                                <tr>
                                    <td>Total</td>
                                    <td class="text-right">
                                        {{
                                            cart.totalArticles.toFixed(2)
                                        }}&nbsp;€
                                    </td>
                                </tr>
                                <tr>
                                    <td>Transport</td>
                                    <td class="text-right">
                                        {{ montantTransport.toFixed(2) }}&nbsp;€
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Total HT</strong></td>
                                    <td class="text-right">
                                        <strong
                                            >{{
                                                totalGlobal.toFixed(2)
                                            }}&nbsp;€</strong
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="!isCartEmpty" class="ml-auto mt-auto">
                            <a
                                class="btn btn-lg btn-success"
                                :class="{ disabled: isCartEmpty }"
                                role="button"
                                href="#"
                                @click.prevent="commander"
                                title="Commander"
                            >
                                <i
                                    class="fas fa-shopping-cart"
                                    aria-hidden="true"
                                ></i
                                >&nbsp;Commander
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
            <div class="row">
                <div class="ml-auto">
                    <!-- <a class="btn btn-sm btn-primary" :class="{'disabled': isCartEmpty}"  role="button" href="#" @click.prevent="commander" title="Commander">
                        <i class="fas fa-shopping-cart" aria-hidden="true"></i>&nbsp;Commander
                    </a> -->
                    <a
                        class="btn btn-sm btn-danger"
                        :class="{ disabled: isCartEmpty }"
                        role="button"
                        href="#"
                        title="Vider"
                        @click="emptyCart"
                    >
                        <i class="fas fa-trash-alt" aria-hidden="true"></i
                        >&nbsp;Vider le panier
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- /.card -->
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { store } from "../store";
import CartStoreModule from "../store/CartStore";
import UserStoreModule from "../store/UserStore";
import { CartProduit, Client } from "../store/types";

import VueNumericInput from "vue-numeric-input";

const cartStore = getModule(CartStoreModule, store);
const userStore = getModule(UserStoreModule, store);

@Component({
    components: {
        VueNumericInput,
    },
})
export default class Cart extends Vue {
    private currentClient: Client | null = null;
    private messageSuccessCommande: string = "";
    private commentCommande: string = "";
    private commandeRefClient: string = "";
    private commandeCodeLivraison: string = "";
    private ligneAdresse: string = "";

    get client() {
        if (this.currentClient == null) {
            this.currentClient = userStore.currentClient;
            if (this.currentClient != null) {
                this.ligneAdresse = this.currentClient.adresseLivraison[2];
            }
        }
        //@ts-ignore
        return this.currentClient;
    }

    set client(client: Client) {
        this.currentClient = client;
        this.ligneAdresse = client.adresseLivraison[2];
    }

    get clients(): Array<Client> {
        return userStore.clients;
    }

    get produits(): Array<CartProduit> {
        return cartStore.cartProduits;
    }

    get cart() {
        return cartStore;
    }
    public getIncrementMax(produit: CartProduit) {
        if (produit.quantiteMaxCommandable < 0) {
            return Infinity;
        } else {
            return Math.ceil(
                (produit.quantiteMaxCommandable - produit.quantiteConsommee) /
                    produit.conditionnement
            );
        }
    }
    public getIncrementMin(produit: CartProduit) {
        if (produit.quantiteMaxCommandable == 0) {
            return 0;
        } else {
            return 1;
        }
    }

    public getConso(produit: CartProduit): number {
        return (
            produit.quantiteConsommee +
            produit.quantite * produit.conditionnement
        );
    }

    public getConsoPourcent(produit: CartProduit): number {
        return Math.round(
            (this.getConso(produit) * 100) / produit.quantiteMaxAnnuelle
        );
    }

    public getConsoColor(produit: CartProduit): string {
        if (this.getConsoPourcent(produit) <= 80) {
            return "green";
        }
        if (this.getConsoPourcent(produit) <= 100) {
            return "orange";
        }
        return "red";
    }

    public setQuantite(produit: CartProduit) {
        cartStore.setQuantite({ produit: produit, qte: produit.quantite });
    }
    public remove(produit: CartProduit) {
        cartStore.removeFomCart(produit);
    }

    public emptyCart() {
        cartStore.emptyCart();
    }

    public commander() {
        // if (this.commandeRefClient == ''){
        //     alert('Votre numéro de commande interne est obligatoire');
        //     return;
        // }
        if (this.currentClient != null) {
            cartStore
                .commander({
                    clientId: this.currentClient.id,
                    commentCommande: this.commentCommande,
                    montantTransport: this.montantTransport,
                    commandeRefClient: this.commandeRefClient,
                    commandeLigneAdresse: this.ligneAdresse,
                    commandeCodeLivraison: this.commandeCodeLivraison,
                })
                .then(
                    (data) => {
                        // console.log('success in cart', data)
                        this.messageSuccessCommande = data.refCommande;
                    },
                    (error) => {
                        console.log("error in cart", error);
                        alert(error);
                    }
                );
        }
    }

    public get totalGlobal(): number {
        return cartStore.totalArticles + this.montantTransport;
    }

    public get montantTransport(): number {
        return userStore.getMontantTransport(cartStore.poidsTotal);
    }
    public get isCartEmpty(): boolean {
        return cartStore.cartProduits.length <= 0;
    }
    public get isMultiClient(): boolean {
        return userStore.isMultiClient;
    }

    public async created() {
        userStore.fetchIfNeededUser().then(() => {
            //@ts-ignore
            this.client = userStore.currentClient;
        });
        await userStore.fetchGrilleTarif();
    }
}
</script>

<style></style>
