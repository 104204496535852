import {
    VuexModule,
    Module,
    Mutation,
    Action,
    getModule,
} from "vuex-module-decorators";
import { CartProduit, Produit } from "./types";
import axios from "axios";
import UserStoreModule from "./UserStore";

@Module({ name: "cart" })
export default class CartStoreModule extends VuexModule {
    private produits: Array<CartProduit> = [];
    public get cartProduits(): Array<CartProduit> {
        return this.produits;
    }
    public set cartProduits(value: Array<CartProduit>) {
        this.produits = value;
    }

    @Mutation
    addToCart({ produit, qte }: { produit: Produit; qte: number }) {
        let p = this.produits.find((p) => p.id === produit.id);

        if (!p) {
            this.produits.push(new CartProduit(produit, qte));
        } else {
            let newQuantite = p.quantite + qte;
            if (
                p.quantiteMaxCommandable > 0 &&
                newQuantite * p.conditionnement > p.quantiteMaxCommandable
            ) {
                p.quantite = p.quantiteMaxCommandable / p.conditionnement;
            } else {
                p.quantite = newQuantite;
            }
            p.quantiteTotale = p.quantite * p.conditionnement;
        }
    }
    @Mutation
    emptyCart() {
        this.produits = Array<CartProduit>();
    }

    @Mutation
    setQuantite({ produit, qte }: { produit: CartProduit; qte: number }) {
        let p = this.produits.find((p) => p.id === produit.id);
        if (p) {
            if (
                p.quantiteMaxCommandable > 0 &&
                qte * p.conditionnement > p.quantiteMaxCommandable
            ) {
                p.quantite = p.quantiteMaxCommandable / p.conditionnement;
            } else {
                p.quantite = qte;
            }
            p.quantiteTotale = p.quantite * p.conditionnement;
            // p.quantite = qte;
            // p.quantiteTotale = qte * p.conditionnement;
        }
    }

    public calcPoduit(p: CartProduit, qte: number): void {
        let newQuantite = p.quantite + qte;
        if (
            p.quantiteMaxCommandable > 0 &&
            newQuantite * p.conditionnement > p.quantiteMaxCommandable
        ) {
            p.quantite = p.quantiteMaxCommandable / p.conditionnement;
        } else {
            p.quantite = newQuantite;
        }
        p.quantiteTotale = p.quantite * p.conditionnement;
    }

    @Mutation
    removeFomCart(produit: CartProduit) {
        const index = this.produits.findIndex((p) => p.id === produit.id);
        this.produits.splice(index, 1);
    }

    @Action({ rawError: true })
    async commander(params: {
        clientId: number;
        commentCommande: string;
        montantTransport: number;
        commandeRefClient: string;
        commandeLigneAdresse: string;
    }): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/commander", {
                    clientId: params.clientId,
                    comment: params.commentCommande,
                    poidsTotal: this.poidsTotal,
                    montantTransport: params.montantTransport,
                    montantArticles: this.totalArticles,
                    produits: this.produits,
                    refClient: params.commandeRefClient,
                    LigneAdresse: params.commandeLigneAdresse,
                })
                .then((response) => {
                    this.context.commit("emptyCart");
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error.response.data.message ?? error.message);
                });
        });
    }

    get nbArticles(): number {
        return this.produits.length;
    }

    get totalArticles(): number {
        const sum = this.produits.reduce((subtotal, item) => {
            return subtotal + item.prixUnitaire * item.quantite;
        }, 0);
        return sum;
    }
    get poidsTotal(): number {
        const sum = this.produits.reduce((subtotal, item) => {
            return subtotal + item.poidsUnitaire * item.quantite;
        }, 0);
        return sum;
    }

    // get prixTransport(): number{
    //     return 0
    //     const userStore=getModule(UserStoreModule, this.context.rootState)
    //     return userStore.getPrixTransport(this.poidsTotal)
    // }
}
